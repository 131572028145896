/* ======================================================================
 * Definitions
 * ====================================================================== */
$anims: 700ms;
$shift: 30vw;

$red:                   #ff1f3f !default;
//$red-dark:              #e84135 !default;
//$blue:                  #007DC3 !default;
//$blue-light:            #0c9ada !default;
//$blue-dark:             #0074c7 !default;
$green:                 #289fd9 !default;
$orange:                #ff1f3f !default;


@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-Regular.woff2") format("woff2"),
  url("fonts/CalibreWeb-Regular.woff") format("woff"),
  url("fonts/CalibreWeb-Regular.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-Italic.woff2") format("woff2"),
  url("fonts/CalibreWeb-Italic.woff") format("woff"),
  url("fonts/CalibreWeb-Italic.eot") format("embedded-opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-Medium.woff2") format("woff2"),
  url("fonts/CalibreWeb-Medium.woff") format("woff"),
  url("fonts/CalibreWeb-Medium.eot") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-MediumItalic.woff2") format("woff2"),
  url("fonts/CalibreWeb-MediumItalic.woff") format("woff"),
  url("fonts/CalibreWeb-MediumItalic.eot") format("embedded-opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-Semibold.woff2") format("woff2"),
  url("fonts/CalibreWeb-Semibold.woff") format("woff"),
  url("fonts/CalibreWeb-Semibold.eot") format("embedded-opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre';
  src:  url("fonts/CalibreWeb-SemiboldItalic.woff2") format("woff2"),
  url("fonts/CalibreWeb-SemiboldItalic.woff") format("woff"),
  url("fonts/CalibreWeb-SemiboldItalic.eot") format("embedded-opentype");
  font-weight: bold;
  font-style: italic;
}


body{
  background: #000000;
  overflow: hidden;
  font-family: 'Calibre', 'Helvetica Neue', 'Helvetica', arial, sans-serif;
}
.container {
  background: #0B1B2B;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  color: #fff;
  font-size: 32px;
  text-align:center;


  &.transitioning-out{
    .game-screen{
      .choice.not-chosen {
        animation: first-out $anims ease-in-out forwards;
      }
      .choice.center {
        animation: center-out $anims ease-in-out forwards;
      }
      .choice.id.left.chosen {
        animation: id-left-selected $anims ease-in-out forwards;
      }
      .choice.id.right.chosen {
        animation: id-right-selected $anims ease-in-out forwards;
      }
      .choice.photo.left.chosen {
        animation: photo-left-selected $anims ease-in-out forwards;
      }
      .choice.photo.right.chosen {
        animation: photo-right-selected $anims ease-in-out forwards;
      }
    }

    .start-screen,
    .end-screen{
      animation: first-out $anims ease-in-out forwards;
    }

    //.blocker{
    //  left: -166.67vw;
    //  opacity: 1.0;
    //}

    canvas{
      opacity: 0;
    }


    .help{
      animation: first-out $anims ease-out forwards;
    }

  }


  &.transitioning-in{
    .game-screen{
      .choice.left {
        animation: first-in $anims ease-out forwards;
      }
      .choice.right {
        animation: second-in $anims ease-out forwards;
      }
    }
    .start-screen,
    .end-screen{
      animation: first-in $anims ease-out forwards;
    }

    //.blocker{
    //  left: -166.67vw;
    //  opacity: 1.0;
    //}


    canvas{
      opacity: 0;
    }

    .help{
      animation: second-in $anims ease-out forwards;
    }

  }


}

.blocker {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 166.67vw;
  height: 100vh;

  left: 100vw;
  background: url('img/blocker.png');
  background-size: 100% 100%;

  -webkit-transition: left $anims*2;
  -moz-transition: left $anims*2;
}

canvas{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  -webkit-transition: opacity $anims;
  -moz-transition: opacity $anims;
}

.screen{
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.screen-inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.start-screen{
  background: #fff;
  overflow: hidden;
  img{
    display: block;
    height: 100vh;
    width: 100vw;
  }
}

.logo{
  width: 25vw;
  display: block;
  margin: 0 auto;
}

.game-description{
  max-width: 80%;
  margin: 0 auto 2vh;
  text-shadow: 0 0 5px RGBA(255,255,255,.3);

  h1{
    text-transform: uppercase;
    font-size: 100px;
    margin-bottom: 0;
  }

  h2{
    font-style: italic;
    margin-top: 0;
    font-size: 55px;
  }

}




button.restart,
button.begin{
  display: inline-block;
  width: 30vh;
  height: 30vh;
  background: #000;
  padding: 0;
  img{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    animation: blinking 800ms 1;
    animation-iteration-count:infinite;
  }
  border: 0 none;
  outline: none;

}

button.restart{
  height: 10vh;
}


.footer{
  position: absolute;
  height: 10vh;
  bottom: 5vh;
  width: 100%;
}


.help{
  position: absolute;
  width: 100vw;
  height: 10vh;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 10vh;
  letter-spacing: .2em;
  font-size: 36px;
  color: #eee;

}


.progress{
  position: absolute;
  width: 100vw;
  height: 10vh;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 10vh;
  letter-spacing: .2em;
  font-size: 36px;
  color: #bbb;
}

.choices{
  position: absolute;
  width: 100vw;
  height: 80vh;
  top: 10vh;
  left: 0;
}

.choice{
  position: absolute;
  top: 50%;
  margin-top: -20vw;
  width: 40vw;

  &.left{
    left: 9vw;
  }
  &.right{
    left: 51vw;
  }
  &.center{
    width: 20vw;
    left: 40vw;
    margin-top: -24vw;
  }

  img{
    width: 100%;
  }

  &.photo{
    img{
      border-radius: 50%;
      box-shadow: 0 5px 10px RGBA(0,0,0,.5);
    }
  }

  //
  //-webkit-transition: background-color $anims ease-out;
  //-moz-transition: background-color $anims ease-out;

}

.reports{
  width: 100vw;
  height: 70vh;
  position: relative;
  top: 10vh
}

.score{
  position: absolute;
  width: 100vw;
  height: 10vh;
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 10vh;
  letter-spacing: 0;
  font-size: 64px;

  .value{
    &.great{
      color: $green;
    }
    &.good{
      color: $orange;
    }
    &.bad{
      color: $red;
    }
  }

}

.report{
  width: 33.33%;
  height: 50%;
  display: block;
  position: absolute;

  &:first-child{
    top: 0;
    left: 0;
  }
  &:nth-child(2){
    top: 50%;
    left: 0;
  }
  &:nth-child(3){
    top: 0;
    left: 33.33%;
  }
  &:nth-child(4){
    top: 50%;
    left: 33.33%;
  }
  &:nth-child(5){
    top: 0;
    left: 66.67%;
  }
  &:nth-child(6){
    top: 50%;
    left: 66.67%;
  }

  img{
    position: absolute;
    display: block;
    height: 110%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    margin: 0 auto;

    &.photo{
      height: 80%;
      border-radius: 50%;
      box-shadow: 0 5px 10px RGBA(0,0,0,.5);
    }
  }

  .result {
    position: absolute;
    width: 8vh;
    height: 8vh;
    background: RGBA(0,0,0,.5);
    border-radius: 50%;
    left: 9%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 2px 2px 5px RGBA(0, 0, 0, .5);

    &.photo{
      left: 18%;
      img{
        border-radius: 50%;
      }
    }

    img{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}


/* ======================================================================
 * Animations
 * ====================================================================== */

@keyframes first-out {
  0% {
    transform:translateX(0%);
    opacity: 1.0
  }
  50%{
    transform:translateX(- $shift);
    opacity: 0
  }
  100%{
    transform:translateX(- $shift);
    opacity: 0
  }
}
@keyframes center-out {
  0% {
    transform:translateX(0%);
    opacity: 1.0
  }
  25%{
    transform:translateX(- $shift);
    opacity: 0
  }
  100%{
    transform:translateX(- $shift);
    opacity: 0
  }
}
@keyframes second-out {
  0% {
    transform:translateX(0%);
    opacity: 1.0
  }
  25% {
    transform:translateX(0%);
    opacity: 1.0
  }
  75%{
    transform:translateX(- $shift);
    opacity: 0
  }
  100%{
    transform:translateX(- $shift);
    opacity: 0
  }
}

@keyframes id-left-selected {
  0% {
    width: 40vw;
    left: 9vw;
    margin-top: -20vw;
  }
  25% {
    left: 30vw;
    width: 40vw;
    margin-top: -20vw;
  }
  50% {
    left: 30vw;
    width: 40vw;
    margin-top: -20vw;
  }
  100% {
    width: 20vw;
    left: 40vw;
    margin-top: -25vw;
  }
}

@keyframes id-right-selected {
  0% {
    width: 40vw;
    left: 51vw;

    margin-top: -20vw;
  }
  25% {
    left: 30vw;
    width: 40vw;

    margin-top: -20vw;
  }
  50% {
    left: 30vw;
    width: 40vw;
    margin-top: -20vw;
  }
  100% {
    width: 20vw;
    left: 40vw;
    margin-top: -25vw;
  }
}


@keyframes photo-left-selected {
  0% {
    left: 9vw;
    opacity: 1;
  }
  20% {
    left: 30vw;
    opacity: 1;
  }
  65% {
    left: 30vw;
    opacity: 1;
  }
  100% {
    left: 100vw;
    opacity: 0;

  }
}

@keyframes photo-right-selected {
  0% {
    left: 51vw;
    opacity: 1;
  }
  20% {
    left: 30vw;
    opacity: 1;
  }
  65% {
    left: 30vw;
    opacity: 1;
  }
  100% {
    left: 15vw;
    opacity: 0;
  }
}


@keyframes first-in {
  0% {
    transform:translateX($shift);
    opacity: 0
  }
  75%{
    transform:translateX(0);
    opacity: 1
  }
  100%{
    transform:translateX(0);
    opacity: 1
  }
}

@keyframes second-in {
  0% {
    transform:translateX($shift);
    opacity: 0
  }
  25% {
    transform:translateX($shift);
    opacity: 0
  }
  100%{
    transform:translateX(0);
    opacity: 1
  }
}

@keyframes blinking {
  0% { opacity: .8; }
  50% { opacity: 1; }
  100% { opacity: .8; }
}